import React from "react"
import { Link } from "gatsby"

// Import Components
import Layout from "../components/layout"
import SEO from "../components/seo"

const Policy = () => {

  return (
    <Layout>
      <SEO title="Privacy Policy - Rooam" />
      <div className="section more-top no-bottom">
        <div className="container medium text-center">
          <h1 className="text-white no-top-bottom">Privacy Policy</h1>
        </div>
      </div>

      <div className="section no-top-bottom">
        <div className="subnav">
          <div className="container medium columns">
            <div className="row clearfix text-center">
              <div className="column break-full">
                <div className="padding no-bottom">
                  <Link to="/legal/">
                    <div className="link background-dark-grey">
                      <p className="smaller text-white font-weight-500 no-top-bottom">Terms & Conditions</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column break-full">
                <div className="padding no-bottom">
                  <Link to="/policy/">
                    <div className="link background-dark-grey active">
                      <p className="smaller text-white font-weight-500 no-top-bottom">Privacy Policy</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section no-top">
        <div className="container medium">
          <p className="text-light-grey">Welcome to Rooam website located at <Link to="/" className="text-link-green">https://rooam.co</Link> (the “<strong className="text-white">Site</strong>”) and operated by Rooam, Inc (“<strong className="text-white">Rooam</strong>”). This Privacy Policy addresses Rooam’s practices regarding information collected from visitors to the Site and users of Rooam’s mobile application (the “<strong className="text-white">App</strong>”). The Site and the App are hereinafter sometimes referred to, collectively, as the “<strong className="text-white">Products</strong>.” This Privacy Policy only addresses information collected directly through or from the Products – it does not address or govern any information-gathering, use, or dissemination practices related to information collected from a visitor (sometimes referred to as "<strong className="text-white">you</strong>" or "<strong className="text-white">your</strong>") other than directly through or from the Products, such as from telephone, facsimile, postal mail, personal delivery, or other or additional offline means or media.</p>
          <p className="text-light-grey">This Privacy Policy is incorporated into Rooam's <Link to="/legal/" className="text-link-green">Terms and Conditions</Link> for the Products by reference, as if set forth fully therein, which must be reviewed and accepted by you. The Products are not intended for children under the age of 18. We do not knowingly collect personal information online from visitors in this age group.</p>
          <p className="text-light-grey">Rooam can be contacted by email at <a href="mailto:hello@rooam.co" target="_blank" className="text-link-green" rel="noopener noreferrer">hello@rooam.co</a>.</p>
          <p className="lead text-white font-weight-600">1. Collection of Personal Information.</p>
          <p className="text-light-grey">Except as specifically set forth herein, Rooam may collect: (a) the email addresses of users that communicate with Rooam via email; and (b) information knowingly provided by the user through the Products. Rooam may also use internet cookies and other identifiers or methods of web analysis (such as Google Analytics) to gather, store, and track certain information related to your use of the Products. If you are uncomfortable with the idea of your information being used in this way, most computer systems and web browsers offer privacy settings and options, such as disabling cookies or opting for “Do Not Track” features. Rooam does not override these settings or options, and encourages you to use them to enhance your choices and personalize your experiences. However, in order to access certain content, features, services, products, or benefits of the Products, you may be required to provide Rooam with certain information, including some types of personally identifying information such as your email, your address, and your phone number. If you do not wish to provide such information through the Products or if you opt to utilize the aforementioned privacy features of your computer system or web browser, you may be unable to obtain certain content, features, services, products, or benefits of the Products.</p>
          <p className="lead text-white font-weight-600">2. Use of Personal Information Collected.</p>
          <p className="text-light-grey">Personal information collected by Rooam may be used by Rooam for limited purposes, including recordkeeping, editorial and feedback purposes, content improvement, or to customize the content and layout of the Products. Personally identifying information, such as names, postal and email addresses, phone numbers, and other personal information voluntarily provided to Rooam may be added to Rooam’s databases and used for future contact regarding updates to the Products or services provided by Rooam. From time to time, Rooam may need to contact you regarding information you submitted to Rooam. You agree that Rooam may use your personal information for such contact with you. Rooam may also desire to contact you for market research purposes or to provide you with marketing information that Rooam believes would be of particular interest. You will have the option of not being contacted by Rooam and opting-out of such communications by contacting Rooam by email at <a href="mailto:hello@rooam.co" target="_blank" className="text-link-green" rel="noopener noreferrer">hello@rooam.co</a>.</p>
          <p className="lead text-white font-weight-600">3. Disclosure of Personal Information to Third Parties.</p>
          <p className="text-light-grey">Except as specifically set forth herein, any personally identifying information that you provide to Rooam through the Products will not be disclosed to other third party entities not affiliated with Rooam. Your personal information and data, whether personally identifying or not, may be disclosed or distributed to another party with which Rooam enters or reasonably may enter into a corporate transaction, such as, for example, a merger, consolidation, acquisition, or asset purchase, or to a third party pursuant to a subpoena, court order, or other form of legal process, in response to a request by or on behalf of any local, state, federal, or other government agency, department, or body, whether or not pursuant to a subpoena, court order, or other form of legal process, or if determined by Rooam in its sole judgment that such disclosure or distribution is appropriate to protect the life, health, or property of Rooam or any other person or entity. Your personal information and data, whether personally identifying or not, may also be provided to third parties that contract with Rooam to provide products, information, or other services that Rooam believes that visitors to the Products may be interested in obtaining. While Rooam may undertake efforts to see that any third party to which Rooam discloses personal information is under an obligation to use the personal information solely for the purposes for which the information was disclosed, such third parties are independent third parties over which Rooam exercises no control. Rooam is not responsible for, and will not be liable for, the conduct, actions, omissions, or information handling or dissemination practices of third parties.</p>
          <p className="lead text-white font-weight-600">4. Links to Other Websites.</p>
          <p className="text-light-grey">The Products may also contain links to third party websites, for the convenience of users in locating information, products, or services that may be of interest. If you access a third party website from a link on the Products, any information you disclose on that website is not subject to this Privacy Policy. It is possible that these links may be used by third parties or others to collect personal or other information about you. Rooam is not responsible for the privacy practices of such websites, advertisers, or third parties or the content of such websites and it is your sole obligation to review and understand the privacy practices and policies of these other websites. Rooam does not control the use of cookies or the collection of information by these third parties, nor how they manage such information. It is solely your obligation to review and understand the privacy practices and policies of these other websites, advertisers, and any third parties.</p>
          <p className="lead text-white font-weight-600">5. Security Measures and Communicating with Rooam.</p>
          <p className="text-light-grey">Rooam uses reasonable efforts to ensure that the Products are generally available. However, there will be occasions when access to the Products will be interrupted or unavailable. Rooam will use reasonable efforts to minimize such disruption where it is within its reasonable control. You agree that Rooam shall not be liable to you for any modification, suspension, or discontinuance of the Products. You understand that the technical processing and transmission of any content through the Products may be transferred unencrypted and involve: (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Please be advised that we do not guarantee that any information sent from our Products will be secure during transmission, nor can we guarantee the confidentiality of any communication or material transmitted to Rooam via the Products or the Internet, including, for example, personal information such as your name and address. Accordingly, Rooam is not responsible for the security of information transmitted via the Internet.</p>
          <p className="lead text-white font-weight-600">6. Access to and Ability to Correct Personal Information; Opt-Out Right.</p>
          <p className="text-light-grey">Upon your request, Rooam will provide you with a summary of any personal information retained by Rooam that has been voluntarily provided by you to Rooam through email, contact forms, or other online avenues. You may modify, correct, change or update your personal information by emailing Rooam at <a href="mailto:hello@rooam.co" target="_blank" className="text-link-green" rel="noopener noreferrer">hello@rooam.co</a>. Except as necessary to obtain the services or information requested, you may opt out of having your personal information, which has been voluntarily provided by you to Rooam through email, contact forms, or other online avenues, prospectively retained by Rooam or used by Rooam for secondary purposes, by contacting Rooam. This provision does not apply to collection of the type of web browser or device used to visit or use the Products or to information provided or collected that does not meet the description hereunder.</p>
          <p className="lead text-white font-weight-600">7. Right to Change Privacy Policy.</p>
          <p className="text-light-grey">Rooam reserves the right at any time to modify, alter or update this Privacy Policy. Your use of the Products following any changes means that you agree to follow and be bound by the Privacy Policy as changed. Any change to the Privacy Policy shall be effective as to any person who has used the Products before the change was made. It is the obligation of users of the Products before the change to learn of changes to the Privacy Policy since their last visit.</p>
          <p className="small text-grey">Dated: April 10th, 2021<br/>Copyright &copy; 2021 Rooam, Inc. All rights reserved.</p>
        </div>
      </div>

    </Layout>
  )
}

export default Policy
